import React, { useState, useEffect } from "react";
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area,
  ReferenceLine,
} from "recharts";
import "./HoldingDetails.css";
import Loader from "./Loader";

export default function NavGraph({ schemeCode, folioStartedOn }) {
  const [navData, setNavData] = useState([]);
  const [timing, settiming] = useState(7);
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null); // State to track the active index

  // Fetch NAV data from MFAPI
  useEffect(() => {
    setIsLoading(true);
    const fetchNavData = async () => {
      try {
        const response = await fetch(`https://api.mfapi.in/mf/${schemeCode}`);
        const data = await response.json();
        const navs = data.data?.slice(0, timing).reverse();
        const formattedNavData = navs.map((item) => ({
          date: item.date,
          nav: parseFloat(item.nav),
        }));
        setNavData(formattedNavData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching NAV data:", error);
        setIsLoading(false);
      }
    };
    fetchNavData();
  }, [timing, schemeCode]);

  if (isLoading)
    return (
      <div className="graph-loading">
        <Loader color="black" />
        Fetching...
      </div>
    );

  const CustomTooltip = ({ active, payload, label }) => {
    if (!active || !payload || payload.length === 0) {
      setActiveIndex(null); // Reset active index when tooltip is not active
      return null;
    }

    const currentIndex = navData.findIndex((data) => data.date === label);
    setActiveIndex(currentIndex); // Set the active index based on the hovered date

    return (
      <div
        style={{
          display: "flex",
          background: "#060621",
          color: "white",
          border: "1px solid #ddd",
          borderRadius: "8px",
          padding: "12px 15px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div style={{ borderRight: "1px solid rgb(69, 92, 103)", paddingRight: "12px" }}>
          <p style={{ fontSize: ".7em" }}>{(navData[activeIndex]?.date === formattedDate) ? 'Investment started on' : 'Date'}</p>
          <p style={{ margin: 0 }}>{label}</p>
        </div>
        <div style={{ paddingLeft: "12px" }}>
          <p style={{ fontSize: ".7em" }}>NAV</p>
          <p style={{ margin: 0, color: "#3CD7AB" }}>{payload[0].value}</p>
        </div>
      </div>
    );
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  const formattedDate = formatDate(folioStartedOn);
  return (
    <>
      <ResponsiveContainer width="100%" height={300} className="chart">
        <AreaChart data={navData}>
          <defs>
            <linearGradient id="areaGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#3CD7AB" stopOpacity={0.4} />
              <stop offset="50%" stopColor="#3CD7AB" stopOpacity={0.2} />
              <stop offset="100%" stopColor="#3CD7AB" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis domain={["auto", "auto"]} />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Area
            type="monotone"
            dataKey="nav"
            stroke="#3CD7AB"
            fill="url(#areaGradient)"
            fillOpacity={0.5}
            strokeWidth={2.5}
            activeDot={{ r: 8 }}
            dot={false}
          />

          {/* Dotted Vertical Line */}
          {activeIndex !== null && (
            <ReferenceLine
              x={navData[activeIndex]?.date}
              stroke="#000"
              strokeDasharray="4 4"
              strokeWidth={2.5}
              label=''
            />
          )}
          <ReferenceLine
            x={formattedDate}
            stroke="#000"
            strokeDasharray="4 4"
            strokeWidth={2.5}
            label=''
          />
        </AreaChart>
      </ResponsiveContainer>
      <div className="duration-btns">
        <button
          onClick={() => settiming(7)}
          className={`${timing === 7 ? "active" : ""}`}
        >
          1W
        </button>
        <button
          onClick={() => settiming(30)}
          className={`${timing === 30 ? "active" : ""}`}
        >
          1M
        </button>
        <button
          onClick={() => settiming(60)}
          className={`${timing === 60 ? "active" : ""}`}
        >
          3M
        </button>
        <button
          onClick={() => settiming(180)}
          className={`${timing === 180 ? "active" : ""}`}
        >
          6M
        </button>
        <button
          onClick={() => settiming(365)}
          className={`${timing === 365 ? "active" : ""}`}
        >
          1Y
        </button>
      </div>
    </>
  );
}
