import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import Loader from "./Loader";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { Toaster, toast } from 'alert';

export default function LoginCard({ showLoginCard, setShowLoginCard }) {
  const [showResetCard, setShowResetCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    pass: "",
    reset: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, formData.email, formData.pass);
      const user = userCredential.user;

      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        const userRole = userData.role;
        navigate(userRole === "admin" ? "/admin" : "/user");
      } else {
        toast.error("User data not found.")
      }
    } catch (err) {
      toast.error("Login failed. Please check your credentials.")
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await sendPasswordResetEmail(auth, formData.reset);
      toast.success("Password reset link sent. Please check your email.");
    } catch (err) {
      toast.error("Failed to send reset email. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const renderLoginForm = () => (
    <div>
      <h3>Login</h3>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          disabled={isLoading}
        />
        <input
          type="password"
          placeholder="Password"
          name="pass"
          value={formData.pass}
          onChange={handleChange}
          required
          disabled={isLoading}
        />
        
        <span className="reset" onClick={() => setShowResetCard(true)}>
          Forgot password?
        </span>
        <button type="submit" className="btn" disabled={isLoading}>
          {isLoading ? <Loader /> : "Submit"}
        </button>
      </form>
    </div>
  );

  const renderResetForm = () => (
    <div>
      <h3>Reset Password</h3>
      <form onSubmit={handleReset}>
        <input
          type="email"
          placeholder="Enter your email"
          name="reset"
          value={formData.reset}
          onChange={handleChange}
          required
          disabled={isLoading}
        />
        <span>We'll send a reset link to your email ID.</span>
        <button type="submit" className="btn" disabled={isLoading}>
          {isLoading ? <Loader /> : "Send"}
        </button>
      </form>
      <span className="back" onClick={() => setShowResetCard(false)}>
        <ArrowLeftIcon/> Back to login
      </span>
    </div>
  );

  if (!showLoginCard) return null;

  return (
    <>
    <Toaster position="top-right"/>
    <div className="card-background">
      <div className={`popcard ${showLoginCard ? "" : "nav-closed"}`}>
        <button className="close-btn" onClick={() => {setShowLoginCard(!setShowLoginCard); setShowResetCard(false)}}>
          &times;
        </button>

        {showResetCard ? (
          renderResetForm()
        ) : (
          renderLoginForm()
        )}
      </div>
    </div>
    </>
  );
}
