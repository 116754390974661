import { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import LoginCard from "./LoginCard";
import { Link } from "react-router-dom";
import { Toaster, toast } from "alert";
export default function NavFloatProfile() {
    const { currentUser, signUserOut, loading , isAdmin } = useAuth();
    const [showLoginCard, setShowLoginCard] = useState(false);
    const [showProfilePopup, setShowProfilePopup] = useState(false);

    const getInitials = (username) => {
        if (!username || typeof username !== 'string') {
            return '';  // Return an empty string if username is null, undefined, or not a string
        }
        const words = username.trim().split(' ');  // Split the username by spaces
        const firstLetter = words[0][0].toUpperCase();  // First letter of the first word
        const secondLetter = words.length > 1 ? words[1][0].toUpperCase() : '';  // First letter after space
        return firstLetter + secondLetter;
    };

    const handleSignOut = () => {
        signUserOut();
        setTimeout(() => {
            setShowProfilePopup(false);
            toast.success("Sign Out successfully!")
        }, 1500);
    };

    return (
        <>
        <Toaster position="top-right" />
            {currentUser ? <span className='login-tab'>
                <span className='profile-initials' title="Open Profile" onClick={() => setShowProfilePopup(!showProfilePopup)}><b>{getInitials(currentUser?.displayName)}</b></span>
            </span> : <span className="btn" onClick={() => setShowLoginCard(!showLoginCard)}>Login</span>}

            <LoginCard showLoginCard={showLoginCard} setShowLoginCard={setShowLoginCard} />

            <div className={`user-profile-popup ${showProfilePopup ? "show" : "hide"}`}>
                <div className="user-name">{currentUser?.displayName}</div>
                <div className="user-email">{currentUser?.email}</div>
                <ul>
                    <li><Link to="/profile">Profile</Link></li>
                    <li><Link to={isAdmin ? "/admin" : "/user"}>{isAdmin ? 'Admin Panel' : 'Portfolio'}</Link></li>
                    <li><a href="#query">Message to Admin</a></li>
                    <li><span onClick={handleSignOut}>
                        {loading ? "Signing Out.." : "Sign Out"}
                    </span></li>
                </ul>
            </div>
        </>
    );
}