import React, { useContext, createContext, useEffect, useState } from "react";
import { auth, db } from '../firebase';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [isAdmin, setIsAdmin] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const signUserOut = async () => {
  setLoading(true);
  try {
    setTimeout(() => {
      signOut(auth);
      navigate("/");
      setLoading(false);
    }, 2000)
  } catch (err) {
    setError(err.message);
    setLoading(false);
  }
};

useEffect(() => {
  const unsubscribe = onAuthStateChanged(auth, async (user) => {
    try {
      setCurrentUser(user);
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setIsAdmin(userDoc.data().role === 'admin');
        } else {
          setIsAdmin(false);
        }
      } else {
        setIsAdmin(false);
      }
    } catch (error) {
      console.error("Error fetching user role:", error);
      setIsAdmin(false);
    }
  });

  return () => unsubscribe();
}, []);


  const value = {
    currentUser,
    error,
    isAdmin,
    signUserOut,
    loading
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
