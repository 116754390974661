import SIPCalculator from '../components/SipCalculator';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

export default function Calculator(){
    return(
        <>
        <Navbar onHome={false}/>
        <SIPCalculator/>
        <Footer/>
        </>
    );
}