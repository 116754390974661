  import guardIcon from '../images/guardIcon.png';
  import trackIcon from '../images/trackIcon.png';
  import supportIcon from '../images/supportIcon.png';
  import docsIcon from '../images/docsIcon.png';
  import rupeeIcon from '../images/rupeeIcon.png';
  const reasons = [
    {
        icon: guardIcon,
        title: "SEBI Registered",
        description: "We are a SEBI registered mutual fund distributor, ensuring complete compliance and security for your investments."
    },
    {
        icon: trackIcon,
        title: "Portfolio tracking",
        description: "We offer a platform where clients can easily track the performance of their mutual fund investments in real-time, providing a clear view of their returns and portfolio composition."
    },
    {
        icon: supportIcon,
        title: "Dedicated Support",
        description: "We provide dedicated, one-on-one financial guidance tailored to each client’s unique situation. This ensures that you receive individualized support throughout your investment journey."
    },
    {
        icon: docsIcon,
        title: "Convenience and Ease",
        description: "We simplify the investment process by handling all the paperwork, KYC, and compliance requirements, making it easy for clients to start investing."
    },
    {
        icon: rupeeIcon,
        title: "Zero Additional Charges",
        description: "Invest in mutual funds at direct plan rates without any additional charges or hidden fees."
    },
]

export default function WhyChooseUs() {
    return (
        <section id='why'>
            <h2>Why Choose <b>royFinds</b>?</h2>
            <div className="cards">
                {reasons.map((reason, index) => (
                    <div className="card" key={index}>
                        <img src={reason.icon} alt="icon" width={120}/>
                        <div>
                            <h3>{reason.title}</h3>
                            <p>{reason.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}