export default function About() {
    return (
        <section id="about">
            <h2>What is <b>royFinds</b>?</h2>
            <p>Welcome to <span style={{ fontFamily: "Unbounded" }}>royFinds</span>!
                <br />
                Your trusted financial planning platform, dedicated to helping you find the best mutual funds that align with your financial goals and risk preferences.
                <br /><br />
                I'm <b>Dipak Roy</b>, your personal financial planner with over 5 years of experience, here to guide you on your journey toward financial success. Whether you're aiming to grow your wealth, save for retirement, or plan for major life milestones, I’m committed to providing you with expert, tailored advice.
                <br /><br />
                At royFinds, we simplify the investment process, helping you select the right mutual funds and track your progress every step of the way. <br />Let’s work together to achieve your financial goals and make your money work for you!
                <br /><br />
                <b>ARN : 267402</b> <br />
                <b>EDIN: E500537</b>
            </p>
        </section>
    );
}