import './App.css';
import Home from './pages/Home';
import { useAuth } from "./contexts/AuthContext";
import User from './pages/User';
import Admin from './pages/Admin';
import { Navigate, Route, Routes } from 'react-router-dom';
import UserProfile from './pages/UserProfile';
import History from './pages/History';
import Disclamier from './pages/Disclamier';
import TnC from './pages/TnC';
import Profile from './pages/Profile';
import Calculator from './pages/Calculator';
function App() {
  const { currentUser } = useAuth();

  const ProtectedRoute = ({ children }) => {
    return currentUser ? children : <Navigate to="/" />;
  };
  
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/sip-calculator" element={<Calculator />} />
      <Route path="/admin" element={<ProtectedRoute><Admin /></ProtectedRoute>}/>
      <Route path="/user" element={<ProtectedRoute><User /></ProtectedRoute>}/>
      <Route path="/user/:username" element={<ProtectedRoute><UserProfile /></ProtectedRoute>}/>
      <Route path="/user/:username/History" element={<ProtectedRoute><History /></ProtectedRoute>}/>
      <Route path="/disclaimer" element={<Disclamier />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/terms-and-conditions" element={<TnC />} />
    </Routes>
  );
}

export default App;