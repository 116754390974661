import React, { useEffect, useState } from 'react';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { Toaster, toast } from 'alert';

export default function Admin() {
  const [email, setemail] = useState('');
  const [pass, setPass] = useState('');
  const [username, setUsername] = useState('');
  const [userPan, setUserPan] = useState('');
  const [users, setUsers] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [searchUser, setSearchUser] = useState("");
  const [showRegisterCard, setShowRegisterCard] = useState(false);


  const getInitials = (username) => {
    if (!username || typeof username !== 'string') {
      return '';  // Return an empty string if username is null, undefined, or not a string
    }
    const words = username.trim().split(' ');  // Split the username by spaces
    const firstLetter = words[0][0].toUpperCase();  // First letter of the first word
    const secondLetter = words.length > 1 ? words[1][0].toUpperCase() : '';  // First letter after space
    return firstLetter + secondLetter;
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, pass);
      const user = userCredential.user;
      await updateProfile(user, { displayName: username });
      // Register new user in Firestore
      const userProfile = {
        email: user.email,
        username,
        userPan,
        userID: user.uid,
        lastUpdatedOn: new Date(),
        role: "user",
        products: {},
      };

      // Store the user profile using the user's UID
      await setDoc(doc(db, 'users', user.uid), userProfile);
      // setIsLoading(false);
      toast.success('User registered successfully');
    } catch (error) {
      toast.error(error.message);
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const usersSnapshot = await getDocs(usersCollection);
        const usersList = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUsers(usersList);
      } catch (error) {
        toast.error("Error fetching users: ", error);
      }
    };

    fetchAllUsers();
  }, []);

  const navigate = useNavigate();

  const handleRowClick = (user) => {
    navigate(`/user/${user.username}`, { state: { user } });
  };

  return (
    <>
      <Toaster position='top-right' />
      <Navbar onHome={false} />
      <div className='container'>
        <div className='admin-page'>
          <div className="clients-container">
            <div className="search-bar">
              <div>
                {/* <h2>All Investors ({users.length > 0 ? users.length-1 : '0'})</h2> */}
                <button onClick={() => setShowRegisterCard(!showRegisterCard)}>Add a new Investor</button>
                <div className={`card-background ${showRegisterCard ? "" : "nav-closed"}`}>
                  <div className="popcard">
                    <button className="close-btn" onClick={() => setShowRegisterCard(!showRegisterCard)}>
                      &times;
                    </button>
                    <form onSubmit={handleRegister}>
                      <h3>New Investor</h3>
                      <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Username"
                      />
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                        placeholder="email"
                      />
                      <input
                        type="text"
                        value={userPan}
                        onChange={(e) => setUserPan(e.target.value)}
                        placeholder="PAN"
                      />
                      <input type="password"
                        id="pass" 
                        onChange={(e) => setPass(e.target.value)} 
                        placeholder='password'
                      />
                      <button type="submit" className='btn'>
                        Register
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <input type='text' placeholder='search with name or email' onChange={(e) => setSearchUser(e.target.value)} />
              </div>
            </div>
            <h2 style={{ marginTop: "25px" }}>All Investors ({users.length > 0 ? users.length - 1 : '0'})</h2>

            <ul className="table-heading">
              <li>Investor</li>
              <li>Email</li>
              <li>PAN</li>
            </ul>
            <div className='clients'>
              <div className="scroll-table">
                {users.length > 0 ? (
                  <div className="users-table">
                    <div className="user-rows">
                      {users
                        .filter(
                          (user) =>
                          ((user.username?.toLowerCase().includes(searchUser.toLowerCase()) ||
                            user.email?.toLowerCase().includes(searchUser.toLowerCase())) &&
                            user.role === "user")
                        )
                        .map((user) => (
                          <div
                            key={user.id}
                            className="user-row"
                            onClick={() => handleRowClick(user)}
                          >
                            <div className="initials">{getInitials(user.username)}</div>
                            <div className='user-data'>
                              <h4>{user.username}</h4>
                              <p>{user.email}</p>
                              <p>{user.userPan}</p>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                ) : (
                  <p>No users found.</p>
                )}
              </div>

            </div>
          </div>

        </div>
      </div>
    </>
  )
}