import { useState } from "react";
import NavFloatProfile from "./NavFloatProfile";
import './Navbar.css';
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
export default function Navbar({onHome}) {
  const [showMobileView, setShowMobileView] = useState(false)
    const navigation = [
        { name: 'About', href: '#about' },
        { name: 'Why Us', href: '#why' },
        { name: 'Query', href: '#query' },
        { name: 'Calculator', href: 'sip-calculator' },
      ];
      
    return (
        <nav>
              {onHome && <div className="mobile-menu" onClick={() => setShowMobileView(!showMobileView)}>
                {showMobileView ? <XMarkIcon/>:<Bars3Icon/>}
              </div>}
            <a href={onHome ? "#top" : "/"} className="logo">
                royFinds
              </a>
              <div className="nav-links">
              {onHome && <div className={`links ${showMobileView ? 'show': 'hide'}`} onClick={() => setShowMobileView(!showMobileView)}>
                {navigation.map((item, index) => (
                    <a
                    key={item.name}
                    href={item.href}
                    className={`link link${index}`}
                    >
                  {item.name}
                </a>
              ))}
              </div>}
              <NavFloatProfile />
              </div>
        </nav>
    );
}