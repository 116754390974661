import { useState } from 'react';
import './Home.css';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Loader from '../components/Loader';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import WhyChooseUs from '../components/WhyChooseUs';
import About from '../components/About';
import { PlayCircleIcon } from '@heroicons/react/16/solid';
import { Toaster, toast} from 'alert';
import { useAuth } from '../contexts/AuthContext';
export default function Home() {
    const [isLoading, setIsLoading] = useState(false);
    const { currentUser } = useAuth();
    const [formData, setFormData] = useState({
        name: currentUser?.displayName || '',
        number: '',
        query: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await setDoc(doc(db, 'queries', formData.name), formData);
            setIsLoading(false);
            toast.success("Query submitted successfully!");
        }
        catch (error) {
            toast.alert("Error submitting query: ", error);
        }
    };



    return (
        <>
        <Toaster position='top-right'/>
            <Navbar onHome={true} />
            <div className='container hero-container'>
                <section id='Hero'>
                    <h1>Your Personalised <br /><span className='gradient-text'>Financial Planner</span></h1>
                    <p>We find the best mutual funds to invest in your future, helping you achieve your financial goals with confidence.</p>
                    <div className='hero-btns'>
                        <button className="invest-btn"  onClick={() => (window.location.href = 'tel:+919434359856')}>Get My Free Consultation</button>
                        <button className="demo-btn"><PlayCircleIcon /> <span>View Demo</span></button>
                    </div>
                </section>
                <About />
                <WhyChooseUs />
                <section id="query">
                    <h2>Having any <b>Financial Query?</b></h2>
                    <div>

                    </div>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="tel"
                            name="number"
                            placeholder="Number"
                            maxLength={10}
                            value={formData.number}
                            onChange={handleChange}
                            required
                        />
                        <textarea
                            name="query"
                            placeholder="Type your Query ..."
                            value={formData.query}
                            onChange={handleChange}
                            required
                        ></textarea>
                        <button type="submit" className='btn' disabled={isLoading}>{isLoading ? <Loader /> : "Send"}</button>
                    </form>
                    <span className="note">Ready to start your investment journey?</span>
                </section>

            </div>
            <Footer />
        </>
    );
}